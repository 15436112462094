.experimentPage {
  //Info icon's color in the columns header
  .helpIcon .icon {
    color: #d4d4d4;
  }

  .encartTop {
    background-color: whitesmoke;
    padding: 1.7rem;
    max-width: 60%;
    border-radius: 5px;
  }
  .download-btn {
    font-size: 14px !important;
    margin-left: 10px;
  }
  .labelsLeft {
    width: 119px;
    padding-right: 50px;
  }
  .ml-2 {
    margin-left: 2.6rem !important;
  }
}
