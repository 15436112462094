@charset "utf-8";

$size-7: 12px !default;
$size-6: 1rem !default;
$size-5: 1.1rem !default;
$size-4: 1.2rem !default;
$size-3: 1.5rem !default;
@import '../../node_modules/bulma/sass/utilities/initial-variables';
@import '../../node_modules/bulma/sass/utilities/functions';
@import url('https://fonts.cdnfonts.com/css/cabinet-grotesk?styles=134960,134955,134954');

$archive: false;

$primary: #e2011b;
$link: #920209;
$text: #111111;
$code: $primary;
$title-color: $primary;
$light-color: #ffffff;
$light-gray: #f5f5f5;
$light-opacity: #ffffff4d;

$nav-hover: linear-gradient(117deg, rgb(54, 54, 54) 0%, rgb(73, 70, 70) 100%);
$dark-bg-color: #282828;
$dark-bg-hover-color: #555555;
$dark-font-color: $light-color;
$hero-banner-bg: #999999;
$body-size: 14px;
$mobile: 480px;
$tablet: 850px;
// $desktop: 960px;
$desktop: 1091px;
$widescreen: 1152px;
$xlscreen: 1620px;
$smallestscreen: 375px;

$footer-background-color: $dark-bg-color;
$footer-color: $light-color;
$footer-padding: 1rem;

/* Button customization*/
.button.search-form {
  line-height: 20px;
  word-spacing: 0;
  height: auto;
  padding: 2px 6px;
  border-color: #767676;
  border-width: 1px;
  background-color: #efefef;
}

@if $archive {
  $dark-bg-color: #a8a088;
  $dark-bg-hover-color: #d0cbbf;
  $dark-font-color: #626262;
  $hero-banner-bg: #d0cbbf;
}

button,
.button {
  font-size: $size-4 !important;
}
body {
  margin: 0;
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text;
  .layout {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    & > section.section {
      padding-top: 20px;
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  border: 1px solid #999;
  &.bio-query {
    background-color: #0ff;
    border-radius: 4px;
    border: none;
  }
}

/*
 * navbar style
 */
@import '../../node_modules/bulma/sass/utilities/derived-variables';
$custom-colors: (
  'bgee-inverted': (
    $dark-bg-color,
    $light-color,
  ),
);
//$custom-colors: ("bgee-link": ($link, $light-color));
//$custom-colors: ("bgee-inverted": ($dark-bg-color, $light-color), "bgee-link": ($link, $light-color));
$colors: map-merge($colors, $custom-colors);

#bgee-complete-menu {
  .navbar-item {
    &:hover {
      color: $light-color;
    }
  }
}

.navbar {
  padding: 10px 30px;
  .navbar-brand {
    a {
      .logo {
        margin: 0 15px !important;
      }
    }
  }

  .navbar-menu {
    .navbar-end {
      a {
        .logo-associations {
            img {
              padding: 0 20px 0 0;
            }
        }
        &:last-child {
          .logo-associations {
            img {
              max-width: 70px;
            }
          }
        }
      }
    }
  }
}

//separate styling of the navbar for the homepage
body.homepage {
  background: #F7F7F7;
  .navbar {
    width: 100%;
    position: absolute !important;
    background: #000000b3 !important;
  }
}
//end separate styling of the navbar for the homepage

.navbar-item {
  font-size: $size-5;
  &:focus {
    background-color: transparent;
  }
  &.custom {
    font-size: $size-6;
  }
  &:hover {
    background: $nav-hover !important;
    border-radius: 8px;
  }
}
.navbar-dropdown.open {
  display: block;
}

@media only screen and (max-width: 1300px) {
  .navbar-item {
    padding: 0 5px !important;
    font: {
      size: .9rem;
    }
  }
}

@media only screen and (max-width: $desktop) {
  $navbar-background-color: $dark-bg-color;
  $navbar-item-color: $light-color;
  $navbar-dropdown-arrow: $light-color;
  .navbar {
    padding: 10px;
    .navbar-burger {
      span {
        width: 22px;
        height: 3px;
        border-radius: 4px;
        &:nth-child(2) {
          top: calc(50% + 1px);
          left: calc(50% - 3px);
        }
        &:nth-child(3) {
          top: calc(50% + 8px);
        }
      }
      &:hover {
        color: $light-color !important;
      }
    }
    img.logo {
      width: 99px;
      max-width: 99px;
      height: 50px;
      max-height: 50px;
    }

    .navbar-menu {
      background-color: $dark-bg-color !important;
      border-radius: 8px;
      &.is-active {
        margin: 10px 0px;
        padding: 20px 0px;
        button {
          margin: 0 0 10px 10px;
          padding: 10px !important;
        }
        .navbar-start {
          .navbar-item {
            padding: 10px !important;
            font: {
              size: 1rem;
            }
            color: $light-color;
          }
        }
        .navbar-end {
          display: flex;
          margin: 10px 0 0;
          border-top: 1px solid rgba(255, 255, 255, 0.1);
          a {
            padding: 10px 0 0;
            .logo-associations {
              img {
                width: 139px;
                margin: 13px;
              }
            }
          }
        }
      }
    }
    .navbar-item.has-dropdown {
      .navbar-link {
        &:hover,
        &.active {
          background-color: $dark-bg-hover-color;
          color: $light-color;
        }
        &::after {
          transition: 0.3s;
          border-color: $light-color !important;
        }
      }
      .navbar-dropdown {
        display: none;
        .custom.navbar-item {
          color: $dark-font-color !important;
          &:hover {
            background-color: $dark-bg-hover-color;
            color: $light-color !important;
          }
        }
      }
      &.is-open {
        .navbar-link {
          background-color: $dark-bg-hover-color;
          color: $light-color;
          &::after {
            transform: rotate(135deg);
            transition: 0.3s;
          }
        }
        .navbar-dropdown {
          display: block;
        }
      }
    }
    .navbar-end {
      align-items: center !important;
    }
    .navbar-end > a.navbar-item:hover {
      background-color: $text !important;
    }
  }

  .home-hero-banner {
    .hero-body {
      .has-text-right {
        text-align: start !important;
      }
    }
  }

}

@media screen and (min-width: $desktop) {
  .navbar-end {
      align-items: center !important;
  }
}

.navbar-brand {
  .navbar-item {
    height: 50px !important;
    padding: 15px;
    width: 130px;
    .logo > img {
      height: 40px;
      max-height: 40px;
      margin-top: 5px;
    }
  }
}
/*
 * END navbar style
 */

.dflex {
  display: flex;
}

/*
 * END footer style
 */
.notification {
  margin-bottom: 0 !important;
}
.title.gradient-underline {
  margin: 1rem 0 !important;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 2px #e2011b solid;
  -webkit-border-image: linear-gradient(
    to right,
    $title-color 70%,
    rgba(146, 2, 9, 0)
  );
  border-image: linear-gradient(to right, $title-color 70%, rgba(146, 2, 9, 0));
  border-image-slice: 1;
}
.title.h3-margin {
  margin: 1rem 0 !important;
  padding-left: 15px;
}

a.internal-link,
a.external-link,
a.mail-link {
  text-decoration: underline;
}
a.external-link {
  background-position: right center;
  background-repeat: no-repeat;
  background-image: linear-gradient(transparent, transparent),
    url('/img/external-link.png');
  background-size: 9px;
  padding-right: 13px;
}
a.ext-as-int-link {
  background-image: none;
}

ul.unordered {
  list-style-type: disc;
}
ol.ordered,
ul.unordered {
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 40px;
  li:not(:first-child) {
    margin-top: 10px;
  }
}

div.card.custom-card {
  box-shadow: none;
  cursor: pointer;
  padding: 0.5rem 0;
  &:hover {
    box-shadow: 0 0.125em 0.25em rgba(10, 10, 10, 1/3);
  }
  .card-content {
    padding-bottom: 0;
  }
  .card-image {
    display: flex;
    justify-content: center;
  }
  .card-title {
    color: $title-color;
    font-weight: 600;
    text-align: center;
    text-decoration: underline;
  }
}
div.card.species {
  cursor: pointer;
  padding: 0;
  width: 100px;
  &:hover {
    box-shadow: 0 0 1em -0.125em rgba(226, 1, 27, 0.9);
  }
  .card-image {
    display: flex;
    justify-content: center;
  }
}

pre {
  border: 1px solid #999;
  border-radius: 4px;
  & > code {
    border: none;
  }
}
$table-cell-border-width: 0;
.card.search-input {
  max-width: 500px;
}

th {
  -ms-user-select: none;
  -webkit-user-select: none;
}
table.table {
  &.sortable {
    thead > tr > th {
      cursor: pointer;
      &:hover {
        opacity: 0.5;
      }
    }
  }
  &.small-font {
    td,
    th {
      font-size: 10px;
    }
  }
  thead {
    td,
    th {
      border-width: 2px 0 !important;
    }
  }
  tbody {
    tr {
      &.is-expanded {
        td {
          .expand-content {
            display: block;
          }
          .expand-button ion-icon {
            transform: rotate(180deg);
            transition: 0.35s;
          }
        }
      }
      td {
        .expand-content {
          display: none;
        }
        .expand-button ion-icon {
          transition: 0.35s;
        }
      }
    }
  }
}

div.separator {
  border-top: 1px solid $text;
  width: 70%;
  margin: auto;
}

span.icon {
  &.open {
    transform: rotate(-180deg);
    transition: 0.35s;
  }
  transition: 0.35s;
}

@import '../../node_modules/bulma/sass/base/all';
@import '../../node_modules/bulma/sass/utilities/all';
@import '../../node_modules/bulma/sass/components/navbar';
@import '../../node_modules/bulma/sass/elements/container';
@import '../../node_modules/bulma/sass/elements/content';
@import '../../node_modules/bulma/sass/elements/icon';
@import '../../node_modules/bulma/sass/elements/image';
@import '../../node_modules/bulma/sass/elements/notification';
@import '../../node_modules/bulma/sass/elements/other';
@import '../../node_modules/bulma/sass/elements/table';
@import '../../node_modules/bulma/sass/elements/title';
@import '../../node_modules/bulma/sass/form/all';
@import '../../node_modules/bulma/sass/grid/all';
@import '../../node_modules/bulma/sass/helpers/all';
@import '../../node_modules/bulma/sass/layout/footer';
@import '../../node_modules/bulma/sass/layout/section';
@import '../../node_modules/bulma/sass/helpers/flexbox';
@import '../../node_modules/bulma/sass/helpers/visibility';

@import '../../node_modules/bulma/bulma';
@import '../../node_modules/@creativebulma/bulma-collapsible';

.custom-accordion {
  .message {
    margin-bottom: 0.5rem;
  }
  .message a,
  .message a.message-header {
    color: $link !important;
    font-weight: normal;
  }
  .message-header {
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    border-radius: 4px;
    &.is-active {
      border-radius: 4px 4px 0 0;
    }
  }
  .message-body {
    border-width: 0;
    background-color: $body-background-color;
    &.is-active {
      border-width: 0 1px 1px;
      border-radius: 0 0 4px 4px;
    }
  }
}

.color-primary {
  color: $primary !important;
}
.color-link {
  color: $link !important;
}
.color-white {
  color: #fff;
  &:hover {
    color: #fcf798;
  }
}

.download-button {
  color: $link;
  border: 2px solid $link;
  padding: 35px 20px;
  border-radius: 5px;
  &:hover {
    background-color: $link;
    color: $background;
  }
}

figure.image {
  &.rounded-border img {
    border-radius: 5px;
  }
  &.is-150x150 {
    height: 150px !important;
    width: 150px !important;
  }

  &.no-responsive > img {
    width: unset !important;
  }
}

.control.checkboxes {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto auto;
  grid-template-columns: auto auto;
  grid-column-gap: 1rem;
  grid-row-gap: 0.25rem;
}
.has-text-uppercase {
  text-transform: uppercase;
}

#creativecommons {
  font-size: 0.75rem;
  display: none;
  &.is-open {
    display: block;
  }
}

.near-columns {
  .column {
    padding-bottom: 0 !important;
  }
}

/* Tooltip */
.tooltip {
  position: relative;
  display: inline-block;
  .tooltip-content {
    visibility: hidden;
    width: max-content;
    max-width: 300px;
    background-color: #fff;
    border: 1px solid #acacac;
    color: $text;
    border-radius: 2px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 0;
    &.isLeft {
      right: 0;
      left: unset;
    }
  }
  &:hover .tooltip-content {
    visibility: visible;
    .tooltip-header {
      background-color: #f5f5f5;
    }
    .tooltip-header,
    .tooltip-body {
      padding: 10px;
    }
  }
}
a.image.cc {
  width: 80px;
}

#notifications {
  position: fixed;
  top: 0;
  left: 1rem;
  right: 1rem;
  z-index: 99;

  & > .notification {
    margin-top: 15px;
  }
}

.cookie-message {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  padding: 2rem;
  background: rgba(0, 0, 0, 0.9);
  @include desktop {
    display: flex;
  }
  .left {
    margin: 5px 0;
    flex: 1;
  }
  .right {
    width: 200px;
    margin: 5px 0;
  }
}
.privacycolor {
  color: #9e9e9e;
}

.archived-link {
  display: flex;
  margin: 10px 0;
  flex-wrap: wrap;
  a.external-link {
    width: 100px;
    height: 100px;
    margin: 0 0.5rem 0 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f2f2f2;
//    color: $primary;
    color: #df011b;
    font: {
      size: 2rem;
      weight: 700;
    }
    text-align: center;
    border-radius: 8px;
    transition: background .3s;
    text-decoration: none;
    &:hover {
      background: $dark-bg-color;
      color: white;
    }
  }
}

.toggle-button {
  padding: 5px !important;
  font-size: 0.6rem !important;
}
label.label.not-default,
span.not-default {
  width: fit-content;
  background-color: #f0eab9;
}

.is-relative {
  position: relative;
}
.top-anat-species {
  height: 60px !important;
  width: 70px !important;
  margin-left: auto;
  margin-right: auto;
}

.gene-species {
  width: 500px;
  cursor: pointer;
  &:hover {
    box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.2);
  }
}

.go-top {
  position: fixed;
  bottom: 50px;
  right: 50px;
  background-color: #fff !important;
  border-width: 3px;
  &:hover,
  &:active,
  &:focus {
    color: $primary !important;
  }
}
.dot-line {
  border-top: 2px dotted $primary;
  width: 2rem;
}

.gap-cluster {
  border-top: 2px dotted $primary;
}
.button-species-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.button-species {
  max-width: 180px;
  border-radius: 15px;
  border: 1px solid $primary;
  flex: 1;
  height: 100%;
  padding: 15px;
  text-align: center;
  cursor: pointer;
}
.card-image.auto-center-100 figure.image {
  margin-left: 0;
  margin-right: 0;
}
a.youtube-preview-image img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
img[src*="#tutoimgborder"] {
  border: 1px solid #363636;
}

@media only screen and (max-width: 600px) {
  .tablet-flex-direction-column {
    flex-direction: column-reverse !important;
    & > * {
      margin: 5px 0;
    }
  }
}
@media only screen and (max-width: $tablet) {
  .section {
    padding: 1rem 0rem;
  }
  #helpers-top-anat {
    flex-direction: column;
    & > *:not(:first-child) {
      margin-top: 0.5rem;
    }
  }
}

ion-icon {
  pointer-events: none;
}

.tags-source {
  .tag.tag-source:not(:last-child) {
    margin-right: 0.15rem;
  }
}
.tag-source {
  padding: 0.2em 0.5em;
  margin: 0 0.15em 0 0 !important;
  border-radius: 0.4em;
  width: 1em;
  line-height: 1em;
  vertical-align: middle;
  border-width: 1px;
  border-style: solid;
  font-size: 0.8em;
  font-family: monospace;
  text-align: center;
  background: #dedede !important;
  color: #b8b8b8 !important;
  border-color: #b8b8b8 !important;
  &.legend {
    width: auto;
  }
  &.present {
    background: #9efa9e !important;
    border-color: #0df20d !important;
    color: #067406 !important;
  }
}

.table td,
.table th {
  padding: 4px !important;
  font-size: 14px;
  color: $text;
}
.input,
.textarea,
.select select {
  font-size: 14px !important;
}

/* Fix an UI Bug from Bulma (https://github.com/jgthms/bulma/issues/2611) */
.select::after {
  z-index: unset !important;
}

.table-search {
  max-width: 200px;
  p {
    font-size: 14px;
  }
  input {
    border-color: #767676 !important;
    border-width: 2px !important;
    height: 24px;
    padding: 0;
    border-radius: 0 !important;
  }
}

label.label {
  font-size: 14px;
}
.rowSearch:hover {
  background-color: #f7f8fa;
  color: #e2011b;
  font-weight: bold;
}
.dropDownSearchForm {
  overflow-y: scroll;
  max-height: 15rem;
  border: 1px solid lightgrey;
  padding: 0.5rem;
  position: absolute;
  z-index: 9;
  margin-top: -0.75rem;
  background-color: white;
}

.card.form {
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  .card-content {
    padding: 9px !important;
  }
}

#general-infos,
#cross-references {
  .column {
    padding-top: 7px;
  }
}
.card-content.species {
  padding: 1rem;
}

.section {
  padding: 1rem 3rem;
}
.markdown {
  ul {
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 40px;
    list-style: disc;
    li {
      list-style: disc;
    }
  }
  ol {
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 40px;
    list-style-type: decimal;
  }
  pre {
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -o-pre-wrap; /* Opera 7 */
    white-space: pre-wrap; /* Since CSS 2.1 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
  }
}
.species-grid {
  display: -ms-grid;
  display: grid;
  grid-column-gap: 5px;
  -ms-grid-columns: repeat(auto-fill, 126px);
  grid-template-columns: repeat(auto-fill, 126px);
  grid-auto-flow: dense;
  justify-content: center;
  .active,
  .fullwidth,
  .expression-species {
    background-color: #282828;
  }
  .fullwidth {
    grid-column: 1 / -1;
    min-height: 50px;
    color: #dbdbdb !important;
    height: 75px;
    .internal-link {
      display: flex;
      align-items: center;
      color: #aaaaaa !important;
      ion-icon {
        font-size: 28px;
        margin-right: 5px;
      }
    }
  }
  .center-in-grid {
    height: 100%;
    width: 100%;
    padding: 10px 0;
    & > .card {
      height: 100%;
      &.species {
        display: flex;
        flex-direction: column;
        .card-content {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  .expression-species {
    grid-column: 1 / -1;
    min-height: 400px;
    color: #dbdbdb !important;
    font-size: 1rem !important;
    .internal-link.grey {
      color: #717177 !important;
      &:hover {
        color: #dbdbdb !important;
      }
    }
    .close {
      margin-left: 5px;
      cursor: pointer;
      ion-icon {
        font-size: 20px;
      }
    }
    .image-container {
      width: 260px;
    }
    .buttons-wrapper {
      margin-bottom: 5px;
      display: flex;
      flex-wrap: wrap;
      & > span:not(:last-child) {
        margin-right: 0.5rem;
        margin-bottom: 5px;
      }
    }
  }
}

@media only screen and (max-width: $tablet) {
  .species-grid {
    .expression-species {
      .image-container {
        display: none;
      }
    }
  }
}
.button.is-multiline {
  min-height: 2.25em;
  white-space: unset;
  height: auto;
  flex-wrap: wrap;
}

.autocomplete-wrapper {
  overflow-y: auto;
  max-height: 15rem;
  border: 1px solid lightgrey;
  width: 100%;
  padding: 0.5rem;
  position: absolute;
  z-index: 9;
  top: 100%;
  background-color: white;
  & > div {
    cursor: pointer;
    margin: 2px 0;
    &:hover {
      background-color: $white;
      color: $primary;
      font-weight: 600;
      margin: 1px 0;
      border-top: 1px solid $primary;
      border-bottom: 1px solid $primary;
    }
  }
}

@media only screen and (max-width: $tablet) {
  #home-species-wrapper .selection.fullwidth {
    flex-direction: column !important;
    height: auto;
    padding: 1rem;
    & > *:not(:first-child) {
      margin-top: 1rem;
    }
  }
  .field.has-addons {
    flex-direction: column;
    & a.button {
      border-radius: 4px !important;
    }
  }
}

@media only screen and (min-width: $tablet) {
  #modal .modal-card {
    max-height: 75vh;
  }
}

.table-modal-button {
  cursor: pointer;
  ion-icon {
    font-size: 28px;
    color: $primary;
  }
}

.gene-expression-modal-grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: minmax(100px, 200px) 1fr;
  grid-template-columns: minmax(100px, 200px) 1fr;
  row-gap: 0.8rem;
  .label {
    font-weight: 700;
    margin: 0;
  }
}

#gene-wrapper {
  display: flex;
  .sidebar {
    width: 13vw;
    min-width: 160px;
    min-height: 50px;
    margin-right: 1rem;
    .side-menu {
      height: 300px;
      .side-menu-wrapper {
        position: fixed;
      }
    }
  }
  #gene-body {
    flex: 1;
    min-height: 50px;
    .head {
      .content {
        margin-left: 1rem;
      }
    }
  }
}

@media only screen and (max-width: $tablet) {
  #gene-wrapper {
    flex-direction: column;
    .sidebar {
      display: none;
      width: auto;
      margin-right: 0;
      .side-menu {
        width: auto;
        height: auto;

        .side-menu-wrapper {
          position: relative;
          width: auto;

          .menu {
            display: none;
          }
        }
      }
    }

    #gene-body {
      margin-top: 1rem;
      .head {
        flex-direction: column;
        .content {
          margin-top: 1rem;
          margin-left: 0;
          justify-content: center;
        }
      }
    }
  }
}

.table-container {
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  overflow-y: hidden;
  max-width: 100%;
}

.complex-table-footer.tablet {
  flex-direction: column;
  & > * {
    margin-bottom: 0.5rem;
  }
}

.species-img img {
  height: 75px;
  width: 75px;
  min-width: 75px;
  min-height: 75px;
}

.gene-expr-row {
  height: 33px;
}
.gene-expr-fields-wrapper {
  margin: -0.5rem;
  & > * {
    margin: 0.5rem;
  }
}
table {
  table-layout: fixed;
  th {
    word-wrap: break-word;
  }
  tr {
    word-wrap: break-word;
    word-break: break-word;
  }
}

.markdown {
  overflow-x: auto;
  overflow-y: hidden;
  table {
    table-layout: auto;
    font-size: 0.9em;
  }
  table tr {
    word-wrap: normal;
    word-break: normal;
  }
  table tr th,
  table tr td {
    border: 1px solid black;
    padding: 2px 4px 2px 4px;
  }
  h3 {
    display: block;
    font-size: 1.1rem;
    margin: 1em 0 0 0;
    font-weight: bold;
    color: #e2011b;
  }
  h4 {
    display: block;
    font-size: 1em;
    margin: 1.33em 0 0 0;
    font-weight: bold;
  }
  h5 {
    display: block;
    font-size: 0.83em;
    margin: 1.67em 0 0 0;
    font-weight: bold;
  }
}
h1.title,
h1.title.in-md {
  color: $title-color;
}

/** CSS Tricks to have the scrollbar at the top of an array **/
.isTableFlipped {
  transform: rotate(180deg) scaleX(-1);
}

.inline-list li {
  display: inline-block;
  margin-right: 5px;
}
.inline-list-separator::after {
  content: ", ";
}

/**********************/
/* HEADER INCL. MODAL */
/**********************/

.custom-burger span:nth-child(1) {
  top: calc(50% - 6px);
}
.custom-burger span:nth-child(2) {
  top: calc(50% - 1px);
}
.custom-burger span:nth-child(3) {
  top: calc(50% + 4px);
}

.custom-burger span {
  background-color: currentColor;
  display: block;
  height: 1px;
  left: 8px;
  position: absolute;
  transform-origin: center;
  transition-duration: 86ms;
  transition-property: background-color, opacity, transform;
  transition-timing-function: ease-out;
  width: 16px;
}

.custom-burger-text {
  margin-left: 25px;
  font-weight: 700;
}

.navbar-item-button {
  margin: 0 10px 0 0;
  padding: 10px;
  color: $light-color;
  font-family: inherit;
  font-size: 1.1rem !important;
  border: 2px solid $light-color;
  border-radius: 8px;
  background: transparent;
  cursor: pointer;
  ion-icon {
    color: $light-color;
    margin-right: 5px;
  }
}

.navbar-item-button:active,
.navbar-item-button:hover {
  background-color: #262626;
}

.menu-modal {
  align-items: start;
  flex-direction: row;
  justify-content: start;

  .menu-modal-brand {
    background-color: $dark-bg-color;
    height: 50px !important;
  }

  .menu-modal-brand figure {
    padding-left: 1em;
    height: 50px;
    margin-top: 8px;
  }

  .menu-modal-card {
    background-color: $light-color;
    overflow: scroll;
    max-height: 100%;
    min-height: 100%;
    width: 300px;
    margin-left: 0;

    .menu-label {
      font-weight: 700 !important;
      font-size: 1.1rem !important;
    }

    .menu {
      margin: 10px;
    }
  }
}

@media screen and (min-width: 769px) {
  .menu-modal-card {
    margin-left: 0;
  }
}

/********/
/* HERO */
/********/

.home-hero-banner {
  padding: 120px 0;
  background: rgb(204,20,20);
  background: radial-gradient(farthest-corner at 31% 55%, #e2011b 0%, #0a0e11 88%);
  border-radius: 0 0 30px 30px;
  .hero-body {
    .hero-version-number {
      span {
        padding: 1px 10px;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 10px;
      }
    }
    h1 {
      font: {
        family: 'Cabinet Grotesk', sans-serif;
        size: 2.5rem;
      }
    }
    @media screen and (min-width: $xlscreen) {
      h1 {
        font: {
          size: 3rem;
        }
      }
    }
    .hero-data-columns {
      .column:not(:first-child) {
        border-left: 1px solid $light-opacity;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
    .gene-search {
      // display: inline-block;
      display: flex;
      justify-content: center;
      a {
        display: flex;
        align-items: center;
        margin: 10px 0;
        padding: 16px;
        background: black;
        color: #fff;
        text-transform: uppercase;
        box-shadow: 0 0 0 0 #000;
        font: {
          size: 1.4rem;
          weight: 400;
        }
        transition: box-shadow .2s ease;
        border-radius: 10px;
        &:hover {
          box-shadow: 0 0 40px 0px #000;
        }
        .ic-dna {
          padding: 0 10px 0 0;
          flex-basis: 0;
        }
      }
    }
  }
}

@media screen and (max-width: $desktop) {
  .column.is-6, .column.is-6-tablet {
    width: 100%;
  }
  .home-hero-banner {
    .hero-body {
      .gene-search {
        display: inline-block;
      }
      .hero-data-columns {
        display: flex;
      }
    }
  }
  .columns:not(.is-desktop) {
    flex-direction: column;
  }
}

/*********************/
/* QUICK NAV (CARDS) */
/*********************/

.quick-nav-cards {
  margin: 25px 0 0 0;
  .inner {
    display: flex;
    justify-content: space-around;
    padding: 10px;
    background: $light-color;
    border-radius: 16px;
    a {
      margin: 0 5px 0 0;
      padding: 18px;
      &:hover {
        background: $light-gray;
        border-radius: 10px;
      }
    }
  }
}

@media screen and (max-width: $desktop) {
  .quick-nav-cards {
    .inner {
      display: block;
      a {
        display: block;
        padding: 5px;
      }
    }
  }
}

/**************/
/* HOME CARDS */
/**************/

.home-cards {
  .card-content {
    min-height: 150px;
    h2 {
      font: {
        family: 'Cabinet Grotesk', sans-serif;
        weight: 700;
      }
    }
    .content {
      p, a {
        margin: 0;
        font: {
          size: 1.2rem;
        }
      }
    }
  }
}

@media screen and (max-width: 1800px) {
  .home-cards {
    .card-content {
      min-height: 200px;
    }
  }
}

@media screen and (max-width: $desktop) {
  .home-cards {
    .card-content {
      min-height: auto;
    }
  }
}

.home-card, .home-card figure, .home-card img {
  border-radius: 16px;
}

.home-card-link:after {
  display: inline-block;
  //-webkit-transform: scale(0.5, 1); /* Safari and Chrome */
  //-moz-transform: scale(0.5, 1); /* Firefox */
  //-ms-transform: scale(0.5, 1); /* IE 9 */
  //-o-transform: scale(0.5, 1); /* Opera */
  transform: scale(0.5, 1); /* W3C */
  content: "\25BA";
  position: relative;
  margin-left: 2px;
}

.home-card h2 a, .home-card h2 a:hover {
  color: $title-color !important;
}

.home-card h2 a:after {
  content: "";
}

.home-card-footer {
  padding: 15px;
  border-radius: 10px;
}

.home-card-img {
  width: 100%;
  height: auto;
  display: block;
  border: 10px solid $primary;
}

/************/
/* NEWS */
/************/

.bgee-news {
  margin: 6rem 0 8rem 0;
}

/************/
/* RESOURCE */
/************/

.bgee-resource-archive {
  margin: 6rem 0;
  .columns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
    .column {
      width: 100%;
      background: $light-color;
      border-radius: 10px;
    }
  }
  .resource-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .resource-logos {
      width: 100%;
      display: flex;
      justify-content: space-around;
      margin: 0 0 10px 0;
    }
    .resource-links {
      text-align: center;
      a.external-link {
        padding-right: 5px;
      }
    }
  }
}

@media screen and (max-width: $desktop) {
  .bgee-resource-archive {
    margin: 6rem 0;
    .columns {
      display: block;
      .column {
        margin: 10px 0;
      }
    }
  }
}

/**********/
/* FOOTER */
/**********/

.footer {
  padding: 40px 0 0;
  border-radius: 30px 30px 0 0;
  background: #09111C;
  .left-wrapper,
  .right-wrapper {
    display: flex;
    a:not(:first-child) {
      margin-left: 15px;
    }
  }
  a {
    color: #ffffff;
    &.image {
      position: relative;
      height: 15px;
      top: 4px;
      img {
        margin: 0;
        position: absolute;
        top: 50%;
      }
    }
  }
  .copyright {
    padding: 140px 0 20px;
  }
}

.nav-footer {
  display: table;
  margin: 40px auto;
  max-width: 1366px;
  line-height: 20px;
  box-sizing: border-box;
  .nav-footer-line {
    display: table-row;
    .nav-footer-col {
      display: table-cell;
      padding: 0 10px;
      .nav-footer-col-head {
        font-size: 1.2em;
        font-weight: 700;
        margin: 6px 0 14px 0;
        white-space: nowrap;
      }
      ul {
        li {
          margin: 0 0 10px;
          white-space: nowrap;
          a {
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
    .nav-footer-col-spacer {
      width: 10%;
      display: table-cell;
    }
  }
}

@media screen and (max-width: $desktop) {
  .footer {
    & > div {
      flex-direction: column;
      justify-content: normal !important;
    }
    .left-wrapper,
    .right-wrapper {
      display: flex;
      flex-direction: column;
    }
    .left-wrapper a:not(:first-child) {
      margin-left: 0;
      margin-top: 10px;
    }
    .right-wrapper a {
      margin-top: 10px;
      &:not(:last-child) {
        margin-right: 0;
      }
    }
  }
  .nav-footer {
    display: flex;
    justify-content: center;
    margin: 40px auto;
    max-width: 1366px;
    line-height: 20px;
    box-sizing: border-box;
    .nav-footer-line {
      display: flex;
      flex-direction: column;
      .nav-footer-col {
        display: block;
        padding: 0 10px;
        .right-wrapper {
          flex-direction: row;
        }
      }
    }
  }
  .copyright {
    .left-wrapper {
      align-items: center;
    }
  }
  body:not(.homepage) {
    footer {
      margin: 100px 0 0;
    }
  }
}

@media screen and (max-width: $smallestscreen) {
  /* GENERAL */
  .section {
    padding: 1rem;
  }
  /* HERO */
  .home-hero-banner {
    .hero-body {
      .gene-search {
        margin: 1rem 0 !important;
      }
      .hero-data-columns {
        width: 100%;
        display: inline-block;
        .column {
          &:not(:first-child) {
            align-items: start;
            border: none;
            border-top: 1px solid $light-opacity;
          }
        }
      }
    }
  }
  /* CARDS */
  .home-cards {
    .card-content {
      h2 {
        padding: 0 0 10px;
        line-height: 2.5rem;
      }
      .content {
        a {
          display: block;
          padding: 10px 0 0;
        }
      }
    }
    .home-card-footer {
      padding-top: 0;
      .home-card-img {
        border-width: 6px;
      }
    }
  }
  /* RESOURCE AND ARCHIVE */
  .bgee-resource-archive {
    .columns {
      div {
        .archived-link {
          a {
            height: 60px;
            font: {
              size: 1.6rem
            }
          }
        }
      }
    }
  }
}
