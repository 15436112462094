table.table.sortable thead > tr > th.hasInfoBubble:hover {
  background-color: rgba(0, 0, 0, 0.05);
  opacity: 1 !important;
}
.helpIcon {
  vertical-align: -webkit-baseline-middle;
}
.table.is-fullwidth {
  width: 98%;
}
